body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;

}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @font-face {
  font-family: 'LeagueSpartan-Bold';
  src:local('LeagueSpartan-Bold'), url(./fonts/LeagueSpartan-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Lulo Clean One';
  src:local('Lulo Clean One'), url(./fonts/Lulo\ Clean\ One.otf) format('truetype');
}

@font-face { 
  font-family: 'Avenir';
  src:local('AvenirLTstd-Book'), url(./fonts/AvenirLTStd-Book.otf) format('truetype');
}

@font-face { 
  font-family: 'Futura';
  src:local('Futura-Book'), url(./fonts/FuturaPTBook.otf) format('truetype');
} */

@font-face {
  font-family: 'LeagueSpartan-Bold';
  src:local('LeagueSpartan-Bold'), url(./fonts/LeagueSpartan-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Lulo Clean One';
  src:local('Lulo Clean One'), url(./fonts/Lulo\ Clean\ One.woff) format('woff');
}

@font-face { 
  font-family: 'Avenir';
  src:local('AvenirLTstd-Book'), url(./fonts/AvenirLTStd-Book.woff) format('woff');
}

@font-face { 
  font-family: 'Futura';
  src:local('Futura-Book'), url(./fonts/FuturaPTBook.woff) format('woff');
}


/* %PUBLIC_URL% */